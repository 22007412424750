import React, { Fragment } from "react";
import AnimatedCursor from "react-animated-cursor";
import Home from "./views/Home";

export default function App() {

  return (
    <Fragment>
      <AnimatedCursor
        innerSize={20}
        outerSize={28}
        color='0, 0, 0'
        outerAlpha={0.2}
        innerScale={0.5}
        outerScale={3}
        outerStyle={{ mixBlendMode: 'difference' }}
      />
      <Home />
    </Fragment>
  );
}
